<!-- Header START -->
<div class="header navbar sticky-top p-0">

  <div class="tooltip-containericon">
    <button name="togglebutton" class="side-nav-toggle btn-toggle pointer" (click)="sidepanel()">
      <i class="fa fa-bars"></i>
    </button>
    <span class="tooltip-messageicon" *ngIf="messageHideShow">Close sidebar</span>
    <span class="tooltip-messageicon" *ngIf="!messageHideShow">Open sidebar</span>
  </div>

  <div class="float-right d-flex pr-3">

    <!-- <div class="dropdown pr-4 text-white pointer mt-2">
            <i class="fa fa-cogs" id="menuButton" data-toggle="dropdown"></i>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="menuButton">
              <a class="dropdown-item" href="#">Practice Configuration</a>
              <a class="dropdown-item" href="#">Billing Configuration</a>
              <a class="dropdown-item" href="#">Day Wise Payer Payment</a>
              <a class="dropdown-item" href="#">Provider Configuration</a>
            </div>
          </div> -->

    <!-- Global Search start -->
    <div class="d-flex align-items-center position-relative mr-3" (mouseleave)="clearDropdown()">
      <input type="text" [(ngModel)]="globalSearchText" (keydown.enter)="navigateTodashboard()"
        (input)="onGlobalSearch()"
        style="background-color: transparent; border: 1px solid #ccc;padding: 5px 12px; font-size: 14px;color: #333; outline: none; width: 310px; border-radius: 5px; caret-color: white; color: white;"
        placeholder="Search..." />

      <span *ngIf="searchIcon"
        style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: white;">
        <i class="fas fa-search"></i>
      </span>
      <span *ngIf="!searchIcon" (click)="clearSearch()"
        style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; font-size: 14px; color: white;">
        ✖
      </span>

      <!-- Dropdown List -->
      <ul *ngIf="filteredList.length>0" class="dropdown-menu show"
        [ngStyle]="{'max-height': dropdownHeight, 'overflow-y': 'auto', 'position': 'absolute', 'top': '100%', 'left': '0', 'z-index': '1000', 'width': '100%'}">
        <li *ngFor="let item of filteredList let i = index" class="dropdown-item" (click)="selectItem(item)"
          tabindex="0" [class.active]="i === selectedIndex">
          {{ item.label }}
        </li>
      </ul>
    </div>
    <!-- Global Search end -->

    <div *ngIf="appEnvironment != 'Production'" class="mr-2">
      <div class="badge bg-orange mt-1 pulse-btn">
        {{ appEnvironment }}
      </div>
      <!-- <div *ngIf="appEnvironment == 'QA'" class="badge bg-orange mt-1">
              {{ appEnvironment }}
            </div> -->
      <!-- <div *ngIf="appEnvironment == 'Staging'" class="badge bg-lime mt-1">
              {{ appEnvironment }}
            </div>
            <div *ngIf="appEnvironment == 'Local'" class="badge bg-cyan mt-1">
              {{ appEnvironment }}
            </div> -->
    </div>



    <div class="dropdown">
      <div data-toggle="dropdown" class="pointer">
        <div class="d-flex align-content-center justify-content-center">
          <span class="avatar avatar-rounded  bg-blue-lt" [title]="firstname">{{initials}}</span>
          <div class="text-white ml-2">
            <!-- <p class="mb-0 mt-1">{{ firstname }}</p> -->
            <!-- <small>{{ username }}</small> -->
          </div>
        </div>
      </div>
      <div class="dropdown-menu dropdown-menu-right">
        <!-- <li class="p-2 px-3"> -->
        <a class="mb-0 dropdown-item py-2">
          <h6 class="mb-0">{{ firstname }} </h6>
          <small class="text-muted">{{username}}</small>
        </a>
        <div class="dropdown-divider"></div>
        <!-- <a href="#"  class="dropdown-item py-2">
                        <i class="ti ti-settings mr-2 text-muted"></i>
                        <span>Configurations</span>
                    </a> -->
        <!-- <a class="mb-0 text-muted dropdown-item">{{ username }}</a> -->
        <!-- <div class="dropdown-divider"></div> -->

        <a href="#" (click)="Logout()" class="dropdown-item py-2">
          <i class="ti ti-power-off mr-2 text-muted"></i>
          <span>Logout</span>
        </a>
        <a class="dropdown-item d-flex align-items-center py-2" *ngIf="hidemaintenancebutton">
          <i class="ti-settings mr-2"></i>
          <span>Maintenance</span>
          <span class="ml-auto">
            <label class="form-check form-switch m-0">
              <input class="form-check-input" type="checkbox" [(ngModel)]="isActive" data-toggle="modal"
                data-target="#confirmmaintenancePopup" style="cursor: pointer;">
            </label>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmmaintenancePopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
      </div>
      <div class="modal-body">
        <h6>{{ maintenancemessage }}</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="maintenancetogglefun()">Yes</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancelchange()">No</button>
      </div>
    </div>
  </div>
</div>


<!--session timeout related chnages added-->
<div id="ishidden">
</div>
<div class="modal modal-blur" id="idleModel" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-9">
            <p class="mt-4">Dear {{name}},</p>
            <p>
              Your session will log out soon. Click continue to resume session
            </p>
          </div>
          <div class="col-md-3">
            <circle-progress [title]="counter" [percent]="percent" [startFromZero]="false" [animation]="true"
              [animationDuration]="200" subtitleColor="#000" titleColor="#000" unitsColor="#000"
              innerStrokeColor="#fd9644" outerStrokeColor="#e76800" subtitleFontSize="12" [subtitle]="'seconds'"
              [showUnits]="false" [clockwise]="false" [radius]="45"></circle-progress>

          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <span class="float-right">
              <button type="button" data-dismiss="modal" (click)="stay()" class="btn btn-success mr-3">
                Continue
              </button>
              <button type="button" data-dismiss="modal" (click)="logout()" class="btn btn-danger">
                Logout
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="modal modal-blur" id="exampleModal2" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="exampleModal2Label" aria-hidden="true">
  <div class="modal-dialog unlock-modal modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="row">
          <div class="col-md-6 d-md-block text-center align-self-center">
            <div class="bg-light p-5">
              <img class="img-fluid" src="../../../assets/img/lock-screen.png" width="350" alt="">
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <h4 class="text-center mb-4 unlock-title">Your Session Is Locked</h4>
              <div class="mt-3">
                <div class="card bg-light mb-5">
                  <div class="card-body p-2 ">
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-lg avatar-blue mr-2">{{firstname | getInitials}}</div>
                      <h4 class="mb-0">{{ firstname }}</h4>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="passwordField" class="form-label" style="color: #475F7B;">Password</label>
                  <input type="password" class="form-control" id="passwordField" [(ngModel)]="passcode" tabindex="1"
                    (keyup.enter)="unlockUser(passcode)">
                  <small *ngIf="invalid" class="text-danger">You have entered invalid password!!</small>
                </div>
                <a class="d-block small text-center mb-3 pointer text-primary" (click)="Logout()">Are you not {{
                  firstname
                  }} ?</a>
                <div class="w-100 text-center mt-4">
                  <button class="btn btn-primary w-75 position-relative" (click)="unlockUser(passcode)" tabindex="2">
                    Unlock
                    <i class="fa fa-arrow-right btn-icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->