import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { TokenEncryptionService } from 'src/app/CallServices/token-encryption.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private tokenEncryptDecrypt: TokenEncryptionService, private cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.tokenEncryptDecrypt.decryptToken(this.cookieService.get("AID"));

    if (authToken) {

      const authReq = req.clone({
        setHeaders: { Authorization: `Bearer ${authToken}` }
      });

      return next.handle(authReq);
    }

    return next.handle(req);

  }
}
